export default {
  30746: {
    AstraToken: {
      address: "0xB9F038640f896edfd18169243efBF7659BC21299",
      abi: [
        "constructor(string name_, string symbol_, uint256 initialSupply_, address owner_)",
        "error ERC20ExceededCap(uint256 increasedSupply, uint256 cap)",
        "error ERC20InsufficientAllowance(address spender, uint256 allowance, uint256 needed)",
        "error ERC20InsufficientBalance(address sender, uint256 balance, uint256 needed)",
        "error ERC20InvalidApprover(address approver)",
        "error ERC20InvalidCap(uint256 cap)",
        "error ERC20InvalidReceiver(address receiver)",
        "error ERC20InvalidSender(address sender)",
        "error ERC20InvalidSpender(address spender)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "event Approval(address indexed owner, address indexed spender, uint256 value)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event Transfer(address indexed from, address indexed to, uint256 value)",
        "event Unpaused(address account)",
        "function allowance(address owner, address spender) view returns (uint256)",
        "function approve(address spender, uint256 value) returns (bool)",
        "function balanceOf(address account) view returns (uint256)",
        "function burn(uint256 value)",
        "function burnFrom(address account, uint256 value)",
        "function cap() view returns (uint256)",
        "function decimals() view returns (uint8)",
        "function mint(address to, uint256 amount)",
        "function name() view returns (string)",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function symbol() view returns (string)",
        "function totalSupply() view returns (uint256)",
        "function transfer(address to, uint256 value) returns (bool)",
        "function transferFrom(address from, address to, uint256 value) returns (bool)",
        "function transferOwnership(address newOwner)",
        "function unpause()",
      ],
      deployTx:
        "0xef45d4c03dd1118a70fa34775c91598027fc03ec0a85a49829281c73c58c4241",
      fullyQualifiedName: "contracts/AST/Token.sol:AstraToken",
    },
  },
  22040: {
    AstraToken: {
      address: "0x24f3811961685888c7a1966cAec194e5444bfC0D",
      abi: [
        "constructor(string name_, string symbol_, uint256 initialSupply_, address owner_)",
        "error ERC20ExceededCap(uint256 increasedSupply, uint256 cap)",
        "error ERC20InsufficientAllowance(address spender, uint256 allowance, uint256 needed)",
        "error ERC20InsufficientBalance(address sender, uint256 balance, uint256 needed)",
        "error ERC20InvalidApprover(address approver)",
        "error ERC20InvalidCap(uint256 cap)",
        "error ERC20InvalidReceiver(address receiver)",
        "error ERC20InvalidSender(address sender)",
        "error ERC20InvalidSpender(address spender)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "event Approval(address indexed owner, address indexed spender, uint256 value)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event Transfer(address indexed from, address indexed to, uint256 value)",
        "event Unpaused(address account)",
        "function allowance(address owner, address spender) view returns (uint256)",
        "function approve(address spender, uint256 value) returns (bool)",
        "function balanceOf(address account) view returns (uint256)",
        "function burn(uint256 value)",
        "function burnFrom(address account, uint256 value)",
        "function cap() view returns (uint256)",
        "function decimals() view returns (uint8)",
        "function mint(address to, uint256 amount)",
        "function name() view returns (string)",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function symbol() view returns (string)",
        "function totalSupply() view returns (uint256)",
        "function transfer(address to, uint256 value) returns (bool)",
        "function transferFrom(address from, address to, uint256 value) returns (bool)",
        "function transferOwnership(address newOwner)",
        "function unpause()",
      ],
      deployTx:
        "0xef45d4c03dd1118a70fa34775c91598027fc03ec0a85a49829281c73c58c4241",
      fullyQualifiedName: "contracts/AST/Token.sol:AstraToken",
    },
    AstraStaking: {
      address: "0x9FACE99Dc78d760a8C81DE8aA1F204f99fe1969f",
      abi: [
        "constructor(address _owner)",
        "error AddressEmptyCode(address target)",
        "error AddressInsufficientBalance(address account)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error FailedInnerCall()",
        "error Lock_BalanceOverflow(uint256 a, uint256 b)",
        "error Lock_BalanceUnderflow(uint256 a, uint256 b)",
        "error Lock_InactiveStake(address token, address staker)",
        "error Lock_InsufficientBalance(address token, address staker, uint256 balance, uint256 neededAmount)",
        "error Lock_InvalidToken()",
        "error Lock_NativeTransferFailed()",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "error ReentrancyGuardReentrantCall()",
        "error SafeCastOverflowedUintToInt(uint256 value)",
        "error SafeERC20FailedOperation(address token)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event StakeChanged(address indexed staker, address indexed token, int256 amount, uint256 timestamp)",
        "event Unpaused(address account)",
        "function getLockAmount(address staker, address token) view returns (uint256)",
        "function getStakeData(address staker, address token) view returns ((bool active, uint256 activeFrom, address staker, address token, uint256 amount))",
        "function lock(address token, uint256 amount) payable",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function totalStaked(address) view returns (uint256)",
        "function transferOwnership(address newOwner)",
        "function unlock(address token, uint256 amount)",
        "function unpause()",
      ],
      deployTx:
        "0x479dd7e2f5a93b6173678f786a49834071b75c401d6aaec735ec48613fdf5cbe",
      fullyQualifiedName: "contracts/staking/AstraStaking.sol:AstraStaking",
    },
    ChronosTrade: {
      address: "0xcF9C5797Ffb90249F6C9dD5C8a7b76A439263082",
      abi: [
        "constructor(address _owner, address _beneficiary)",
        "error AddressEmptyCode(address target)",
        "error AddressInsufficientBalance(address account)",
        "error CantBuy(uint256 orderId, uint256 amountToBuy, uint256 avaliableToBuy)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error FailedInnerCall()",
        "error InvalidAmount()",
        "error InvalidParams()",
        "error MathOverflowedMulDiv()",
        "error NativeSendFailed()",
        "error OnlySeller(address seller, address caller)",
        "error OrderNotFound(uint256 orderId)",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "error SafeCastOverflowedIntToUint(int256 value)",
        "error SafeERC20FailedOperation(address token)",
        "event OrderCancelled(uint256 indexed orderId, uint256 amountReturned)",
        "event OrderCreated(uint256 indexed orderId, address indexed seller, address tokenToSell, address quoteToken, uint256 pricePerToken, int8 pricePower, uint256 amount)",
        "event OrderFilled(uint256 indexed orderId)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event Sold(uint256 indexed orderId, address indexed buyer, address indexed recipient, uint256 amountBought)",
        "event Unpaused(address account)",
        "function FEE_DECIMALS() view returns (uint8)",
        "function MAX_PRICE_POWER() view returns (int8)",
        "function ONE_HUNDRED_PERCENT() view returns (uint32)",
        "function beneficiary() view returns (address)",
        "function buy(uint256 orderId, address recipient, uint256 amount) payable",
        "function cancelOrder(uint256 orderId)",
        "function feePercent() view returns (uint32)",
        "function nextOrderId() view returns (uint256)",
        "function orders(uint256 orderId) view returns (address seller, address tokenToSell, address quoteToken, uint256 amountIn, uint256 amountLeft, uint256 price, int8 pricePower, bool active)",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function sell(address tokenToSell, address quoteToken, uint256 pricePerOneToken, int8 pricePower, uint256 amountToSell) payable returns (uint256 orderId)",
        "function setBeneficiary(address newBeneficiary)",
        "function setFee(uint32 fee)",
        "function transferOwnership(address newOwner)",
        "function unpause()",
      ],
      deployTx:
        "0x54d8bb004dd9a7500e8787dd753078d5fe11c9276a1a5d86c6a992d832921d0c",
      fullyQualifiedName: "contracts/chronos/ChronosTrade.sol:ChronosTrade",
    },
    EclipseManager: {
      address: "0xfa76e99eA945C20F21276cE4dF295511eD6a5611",
      abi: [
        "constructor(address _owner, address _staking, address _stakingToken, address _router)",
        "error AddressEmptyCode(address target)",
        "error AddressInsufficientBalance(address account)",
        "error ContractNotSet(string contractName)",
        "error FailedInnerCall()",
        "error InvalidValueAmount(uint256 expected, uint256 actual)",
        "error NotLinked(string contractName, address newContract)",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "error ReentrancyGuardReentrantCall()",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error SafeERC20FailedOperation(address token)",
        "event MathModuleSet(address indexed mathModule)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event PoolClosed(address indexed pair, uint256 withdrawnAmount, address withdrawTo)",
        "event PoolCreated(address indexed pair, address treasury, address provisor)",
        "event PoolFactorySet(address indexed factory)",
        "event PoolPaused(address indexed pair)",
        "event PoolUnpaused(address indexed pair)",
        "event RouterSet(address router)",
        "event StakingSet(address staking)",
        "event StakingTokenSet(address stakingToken)",
        "function closePool(address pair, address withdrawTo)",
        "function connectModules(address _mathModule, address _poolFactory)",
        "function createPool(address pair, address eclipsePartToken, uint256 treasuryAmount) payable returns (address treasury, address provisor)",
        "function fundTreasury(address pair, uint256 amount) payable",
        "function getPairContracts(address pair) view returns (address, address)",
        "function mathModule() view returns (address)",
        "function owner() view returns (address)",
        "function pausePool(address pair)",
        "function poolFactory() view returns (address)",
        "function provisor(address pair) view returns (address)",
        "function renounceOwnership()",
        "function router() view returns (address)",
        "function setDefaultPremiumPercent(uint48 percent)",
        "function setMathModule(address newMathModule)",
        "function setPoolFactory(address newPoolFactory)",
        "function setPremiumPercent(uint48 percent, uint256 poolLifetime)",
        "function setRouter(address newRouter)",
        "function setStaking(address newStaking)",
        "function setStakingToken(address newStakingToken)",
        "function setTreshold(address pair, uint256 treshold)",
        "function staking() view returns (address)",
        "function stakingToken() view returns (address)",
        "function transferOwnership(address newOwner)",
        "function treasury(address pair) view returns (address)",
        "function unpausePool(address pair)",
        "function withdrawTreasury(address pair, uint256 amount, address to)",
        "function setMultiplier(address pair, uint48 multiplier)",
      ],
      deployTx:
        "0x62029d05f12d78be8b899f24d2f8dcbd5274f08992ea481030bfd4dbfaeae254",
      fullyQualifiedName: "contracts/eclipse/EclipseManager.sol:EclipseManager",
    },
  },
  16718: {
    AstraToken: {
      address: "0x5ceCBde7811aC0Ed86Be11827AE622b89Bc429DF",
      abi: [
        "constructor(string name_, string symbol_, uint256 initialSupply_, address owner_)",
        "error ERC20ExceededCap(uint256 increasedSupply, uint256 cap)",
        "error ERC20InsufficientAllowance(address spender, uint256 allowance, uint256 needed)",
        "error ERC20InsufficientBalance(address sender, uint256 balance, uint256 needed)",
        "error ERC20InvalidApprover(address approver)",
        "error ERC20InvalidCap(uint256 cap)",
        "error ERC20InvalidReceiver(address receiver)",
        "error ERC20InvalidSender(address sender)",
        "error ERC20InvalidSpender(address spender)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "event Approval(address indexed owner, address indexed spender, uint256 value)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event Transfer(address indexed from, address indexed to, uint256 value)",
        "event Unpaused(address account)",
        "function allowance(address owner, address spender) view returns (uint256)",
        "function approve(address spender, uint256 value) returns (bool)",
        "function balanceOf(address account) view returns (uint256)",
        "function burn(uint256 value)",
        "function burnFrom(address account, uint256 value)",
        "function cap() view returns (uint256)",
        "function decimals() view returns (uint8)",
        "function mint(address to, uint256 amount)",
        "function name() view returns (string)",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function symbol() view returns (string)",
        "function totalSupply() view returns (uint256)",
        "function transfer(address to, uint256 value) returns (bool)",
        "function transferFrom(address from, address to, uint256 value) returns (bool)",
        "function transferOwnership(address newOwner)",
        "function unpause()",
      ],
      deployTx:
        "0xef45d4c03dd1118a70fa34775c91598027fc03ec0a85a49829281c73c58c4241",
      fullyQualifiedName: "contracts/AST/Token.sol:AstraToken",
    },
    AstraStaking: {
      address: "0x5349ac23F0b279740B38F8A1c68896DB88232567",
      abi: [
        "constructor(address _owner)",
        "error AddressEmptyCode(address target)",
        "error AddressInsufficientBalance(address account)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error FailedInnerCall()",
        "error Lock_BalanceOverflow(uint256 a, uint256 b)",
        "error Lock_BalanceUnderflow(uint256 a, uint256 b)",
        "error Lock_InactiveStake(address token, address staker)",
        "error Lock_InsufficientBalance(address token, address staker, uint256 balance, uint256 neededAmount)",
        "error Lock_InvalidToken()",
        "error Lock_NativeTransferFailed()",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "error ReentrancyGuardReentrantCall()",
        "error SafeCastOverflowedUintToInt(uint256 value)",
        "error SafeERC20FailedOperation(address token)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event StakeChanged(address indexed staker, address indexed token, int256 amount, uint256 timestamp)",
        "event Unpaused(address account)",
        "function getLockAmount(address staker, address token) view returns (uint256)",
        "function getStakeData(address staker, address token) view returns ((bool active, uint256 activeFrom, address staker, address token, uint256 amount))",
        "function lock(address token, uint256 amount) payable",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function totalStaked(address) view returns (uint256)",
        "function transferOwnership(address newOwner)",
        "function unlock(address token, uint256 amount)",
        "function unpause()",
      ],
      deployTx:
        "0x479dd7e2f5a93b6173678f786a49834071b75c401d6aaec735ec48613fdf5cbe",
      fullyQualifiedName: "contracts/staking/AstraStaking.sol:AstraStaking",
    },
    ChronosTrade: {
      address: "0x9accC139C0f60b9bd09A0f3F59068884d544fd38",
      abi: [
        "constructor(address _owner, address _beneficiary)",
        "error AddressEmptyCode(address target)",
        "error AddressInsufficientBalance(address account)",
        "error CantBuy(uint256 orderId, uint256 amountToBuy, uint256 avaliableToBuy)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error FailedInnerCall()",
        "error InvalidAmount()",
        "error InvalidParams()",
        "error MathOverflowedMulDiv()",
        "error NativeSendFailed()",
        "error OnlySeller(address seller, address caller)",
        "error OrderNotFound(uint256 orderId)",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "error SafeCastOverflowedIntToUint(int256 value)",
        "error SafeERC20FailedOperation(address token)",
        "event OrderCancelled(uint256 indexed orderId, uint256 amountReturned)",
        "event OrderCreated(uint256 indexed orderId, address indexed seller, address tokenToSell, address quoteToken, uint256 pricePerToken, int8 pricePower, uint256 amount)",
        "event OrderFilled(uint256 indexed orderId)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event Paused(address account)",
        "event Sold(uint256 indexed orderId, address indexed buyer, address indexed recipient, uint256 amountBought)",
        "event Unpaused(address account)",
        "function FEE_DECIMALS() view returns (uint8)",
        "function MAX_PRICE_POWER() view returns (int8)",
        "function ONE_HUNDRED_PERCENT() view returns (uint32)",
        "function beneficiary() view returns (address)",
        "function buy(uint256 orderId, address recipient, uint256 amount) payable",
        "function cancelOrder(uint256 orderId)",
        "function feePercent() view returns (uint32)",
        "function nextOrderId() view returns (uint256)",
        "function orders(uint256 orderId) view returns (address seller, address tokenToSell, address quoteToken, uint256 amountIn, uint256 amountLeft, uint256 price, int8 pricePower, bool active)",
        "function owner() view returns (address)",
        "function pause()",
        "function paused() view returns (bool)",
        "function renounceOwnership()",
        "function sell(address tokenToSell, address quoteToken, uint256 pricePerOneToken, int8 pricePower, uint256 amountToSell) payable returns (uint256 orderId)",
        "function setBeneficiary(address newBeneficiary)",
        "function setFee(uint32 fee)",
        "function transferOwnership(address newOwner)",
        "function unpause()",
      ],
      deployTx:
        "0x54d8bb004dd9a7500e8787dd753078d5fe11c9276a1a5d86c6a992d832921d0c",
      fullyQualifiedName: "contracts/chronos/ChronosTrade.sol:ChronosTrade",
    },
    EclipseManager: {
      address: "0x6f483A1a0EAE4CFACBcee562F5F0B56E9D3D5eA0",
      abi: [
        "constructor(address _owner, address _staking, address _stakingToken, address _router)",
        "error AddressEmptyCode(address target)",
        "error AddressInsufficientBalance(address account)",
        "error ContractNotSet(string contractName)",
        "error FailedInnerCall()",
        "error InvalidValueAmount(uint256 expected, uint256 actual)",
        "error NotLinked(string contractName, address newContract)",
        "error OwnableInvalidOwner(address owner)",
        "error OwnableUnauthorizedAccount(address account)",
        "error EnforcedPause()",
        "error ExpectedPause()",
        "error ReentrancyGuardReentrantCall()",
        "error SafeERC20FailedOperation(address token)",
        "event MathModuleSet(address indexed mathModule)",
        "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
        "event PoolClosed(address indexed pair, uint256 withdrawnAmount, address withdrawTo)",
        "event PoolCreated(address indexed pair, address treasury, address provisor)",
        "event PoolFactorySet(address indexed factory)",
        "event PoolPaused(address indexed pair)",
        "event PoolUnpaused(address indexed pair)",
        "event RouterSet(address router)",
        "event StakingSet(address staking)",
        "event StakingTokenSet(address stakingToken)",
        "function closePool(address pair, address withdrawTo)",
        "function connectModules(address _mathModule, address _poolFactory)",
        "function createPool(address pair, address eclipsePartToken, uint256 treasuryAmount) payable returns (address treasury, address provisor)",
        "function fundTreasury(address pair, uint256 amount) payable",
        "function getPairContracts(address pair) view returns (address, address)",
        "function mathModule() view returns (address)",
        "function owner() view returns (address)",
        "function pausePool(address pair)",
        "function poolFactory() view returns (address)",
        "function provisor(address pair) view returns (address)",
        "function renounceOwnership()",
        "function router() view returns (address)",
        "function setDefaultPremiumPercent(uint48 percent)",
        "function setMathModule(address newMathModule)",
        "function setPoolFactory(address newPoolFactory)",
        "function setPremiumPercent(uint48 percent, uint256 poolLifetime)",
        "function setRouter(address newRouter)",
        "function setStaking(address newStaking)",
        "function setStakingToken(address newStakingToken)",
        "function setTreshold(address pair, uint256 treshold)",
        "function staking() view returns (address)",
        "function stakingToken() view returns (address)",
        "function transferOwnership(address newOwner)",
        "function treasury(address pair) view returns (address)",
        "function unpausePool(address pair)",
        "function withdrawTreasury(address pair, uint256 amount, address to)",
        "function setMultiplier(address pair, uint48 multiplier)",
      ],
      deployTx:
        "0x62029d05f12d78be8b899f24d2f8dcbd5274f08992ea481030bfd4dbfaeae254",
      fullyQualifiedName: "contracts/eclipse/EclipseManager.sol:EclipseManager",
    },
  },
};
